(function () {
  function initToggle() {
    if (window.innerWidth >= 768) {
      return;
    }

    const toggleEls = document.querySelectorAll('[data-toggle-class]');

    Array.from(toggleEls).forEach(function (toggleEl) {
      toggleEl.addEventListener('click', function () {
        const { toggleClass } = toggleEl.dataset;

        if (document.body.classList.contains(toggleClass)) {
          document.body.classList.remove(toggleEl.dataset.toggleClass);
        } else {
          document.body.classList.add(toggleEl.dataset.toggleClass);
        }
      });
    });
  }

  window.addEventListener('resize', initToggle);

  initToggle();
})();
